<template>
  <section class="pb-4 container">
    <GoBack />
    <Breadcrumb class="sobre_section_bc-color">
      <template v-slot:breadcrumbLinks>
        <router-link
          tag="p"
          :to="{ name: link.href }"
          v-for="(link, idx) in breadcrumbItems"
          :key="idx"
        >
          {{ link.text }}
        </router-link>
      </template>
    </Breadcrumb>
    <v-row>
      <v-col class="d-flex justify-center align-center mt-3">
        <FlagTitle title="Vistoria e ADVI" />
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center align-center mb-5 flex-md-row-reverse">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto"
      >
        <div class="section__container_card">
          <Card
            title_alignment="center"
            borderColor="#C92F05"
            titleColor="#C92F05"
          >
            <template v-slot:conteudo>
              <p>
                A etapa subsequente à oficina de cartografia social é a
                vistoria, realizada pela Synergia Socioambiental, representando
                a Fundação Renova/Samarco, e pela Cáritas Brasileira Regional
                Minas Gerais, com a participação dos membros do núcleo familiar.
                Embora a Fundação Renova tenha optado por não participar da
                cartografia social, exigiu a instituição da etapa de vistoria
                com a sua presença.
              </p>
              <p>
                Para muitos atingidos e atingidas, a realização da vistoria no
                terreno declarado significou a primeira vez que retornaram à
                comunidade após o rompimento da barragem. Assim, essa etapa foi
                marcada por fortes momentos de emoção e, muitas vezes,
                manifestação de revolta ao se depararem com toda a destruição
                comunitária e com seus pertences pessoais em meio à lama de
                rejeitos tóxicos.
              </p>
              <p>
                Para a Cáritas, a metodologia de vistoria utilizada consiste em
                uma inspeção de campo dos terrenos atingidos, para verificar in
                loco as condições dos elementos que os constituem. Durante a
                inspeção, a equipe realiza o registro fotográfico para
                apontamento da situação atual do terreno e, sempre que os
                elementos são passíveis de observação, identificação e acesso
                direto, realiza-se a medição das construções e demais
                benfeitorias. Além disso, as coordenadas geográficas dos limites
                e dos elementos remanescentes após o rompimento da barragem são
                levantadas.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/vistoria_advi_01.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Vistoria técnica realizada pela Cáritas em um terreno devastado de
            Bento Rodrigues com o acompanhamento, participação e apontamentos
            dos atingidos. Foto: Acervo Cáritas, 2018.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center mx-auto mb-3"
      >
        <div class="section__container_card">
          <Card borderColor="#C92F05" titleColor="#C92F05">
            <template v-slot:conteudo>
              <p>
                As informações cadastradas pela Cáritas durante a vistoria dos
                terrenos atingidos são registradas em fichas de campo das áreas
                de Arquitetura e Ciências Agrárias, com foco na identificação de
                construções e benfeitorias, produção vegetal e produção animal,
                situação dos corpos d’água, matas, paisagem, acessos, dentre
                outros. Essas fichas permitem a coleta e o registro das
                características físicas e de utilização dos bens de uso e/ou
                propriedade do núcleo familiar.
              </p>
              <p>
                Em acordo com a recomendação da Organização Mundial de Saúde
                (OMS) acerca do isolamento social como medida de controle
                sanitário no país, as vistorias técnicas nas áreas atingidas
                deixaram de ser realizadas a partir de março de 2020, no intuito
                de preservar a saúde das pessoas envolvidas. A Cáritas propôs,
                então, a alteração da metodologia de vistoria original baseada
                na forma de produção que foi realizada nos vários terrenos
                inacessíveis, elaborando um escopo de atendimento remoto para
                validação e compartilhamento das informações prestadas durante
                todas as etapas do cadastro. A proposta das ADVIs
                (Autodeclaração para Validação das Informações) foi apresentada
                e acatada em reunião ordinária da CABF e, após diálogo com a
                Synergia e a Fundação Renova, acompanhado pelo MPMG, o que
                tornou a finalização dos dossiês possível nesse contexto.
              </p>
            </template>
          </Card>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="80%"
            src="../../assets/vistoria_advi_02.png"
            alt=""
          />
          <p style="font-size: 0.5em">
            Material usado para a realização de vistorias. Foto: Isabela
            Ladeira, 2021.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center mb-5">
      <div class="d-flex flex-column justify-center align-center">
        <v-btn
          :to="{ name: 'sobre_tomadas_de_termo' }"
          small
          color="#E06919"
          class="white--text pa-5"
        >
          Conhecer "Tomadas de Termo"
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </v-row>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import FlagTitle from "../../components/FlagTitle.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: { Card, Breadcrumb, FlagTitle, GoBack },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<       Quem somos       /",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "O Cadastro /",
          href: "sobre_cadastro",
        },
        {
          text: "Vistoria e ADVI",
          href: "sobre_vistoria_advi",
        },
      ],
    };
  },
};
</script>

<style scoped>
ul,
li {
  font-size: 0.8em;
}

.form__image img {
  max-width: 70%;
}
.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__container_card {
  max-width: 40em;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.assessoria_card {
  padding: 2em;
}

.assessoria_card p {
  padding: 1em;
  text-align: center;
  font-size: 0.7em;
}

.assessoria__items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 7em;
}

.assessoria__items p {
  text-align: center;
  font-size: 0.6em;
  max-width: 80%;
}
</style>
